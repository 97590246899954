import React, { useState } from 'react'
import styles from './Alert.module.css'
import Link from 'src/components/Link'

interface Props {
  message: string
  onClose: () => void
}

function Alert (props: Props) {
  const { message, onClose } = props
  const [open, setOpen] = useState<boolean>(true)

  return message ? (
    <div className={styles.container}>
      <div className={styles.message}>{message}</div>
      <div className={styles.close}>
        <Link onClick={onClose}>X</Link>
      </div>
    </div>
  ) : null
}

export default Alert
